// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery')
// require("lightbox")

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '../bootstrap/application.scss'

// import 'lightbox'

const moment = require('moment')
const Tabulator = require('tabulator-tables');
const feather = require("feather-icons")

require("fullcalendar")
require("fullcalendar-scheduler")
// require("ckeditor")
// require("dropzone")
require("data-confirm-modal")
require("datatables.net-bs4")
require("datatables.net-autofill-bs4")
require("datatables.net-responsive-bs4")
// require("select2")

import $ from 'jquery';
global.$ = jQuery;

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// require("lightbox2")
// require("lity")

// const Luminous = require('luminous-lightbox').Luminous;
// const LuminousGallery = require('luminous-lightbox').LuminousGallery;
import '../luminous-lightbox/application.scss'
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

// require("@ckeditor/ckeditor5-upload")
// require("@ckeditor/ckeditor5-build-inline")
// require("@ckeditor/ckeditor5-build-balloon")
// require("@ckeditor/ckeditor5-build-balloon-block")
// require("@ckeditor/ckeditor5-build-decoupled-document")

// import '../vender/ckeditor/ckeditor.js'
// import './lib/dateEditor'
// import '../vender/fullcalendar/main.min.js'
// import '../vender/dropzone/dropzone.min.js'
// import '../vender/dropzone/basic.min.css'
// import '../vender/dropzone/dropzone.min.css'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(() => {
//   $(document).on('turbolinks:load', () => {
//     // $('#calendar').fullCalendar({})
//   })
// })